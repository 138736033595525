export default {
	messages: {
		ru: {
			'введите правильный номер': 'Введите валидный номер телефона',
			'html.buAuto.autocheck129': 'Или оставьте свой номер телефона и мы вам перезвоним (это бесплатно)',
			'common.applicationSuccessfullyAdopted': 'Ваша заявка успешно принята',
			'common.perezvonitemne': 'Перезвоните мне',
			'common.timeConvenientCall': 'удобное для звонка время',
			'html.buAuto.nomertelefona': 'Номер телефона',
			'Ваши персональные данные будут обработаны и защищены в соответствии с': 'Ваши персональные данные будут обработаны и защищены в соответствии с',
			'Политикой приватности': 'Политикой приватности',
			'Ваше імя': 'Ваше имя',
		},
		uk: {
			'введите правильный номер': 'Введіть валідний номер телефону',
			'html.buAuto.autocheck129': 'Або залиште свій номер телефону й ми вам передзвонимо (це безкоштовно)',
			'common.applicationSuccessfullyAdopted': 'Ваша заявка успішно прийнята',
			'common.perezvonitemne': 'Передзвоніть мені',
			'common.timeConvenientCall': 'зручний для дзвінка час',
			'html.buAuto.nomertelefona': 'Номер телефону',
			'Ваши персональные данные будут обработаны и защищены в соответствии с': 'Ваші персональні дані будуть оброблені та захищені згідно до',
			'Политикой приватности': 'Політики приватності',
			'Ваше імя': 'Ваше ім\'я',
		},
	}
};
