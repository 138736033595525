import ROUTE_NAME from '../router/types/routeNames';

const CALLBACK_IDS = {
  [ROUTE_NAME.CHECK_SELECTION]: 'checkSelection', // https://auto.ria.com/uk/check_selection/31957733/
  [ROUTE_NAME.AUTOTEST]: 'autocheck', // https://auto.ria.com/uk/autocheck/
  [ROUTE_NAME.LIST_STO]: 'sto', // https://auto.ria.com/uk/sto/
  [ROUTE_NAME.CENTR_PEREVIROK]: 'verificationCenter', // https://auto.ria.com/uk/verification-center/
};

export default CALLBACK_IDS;
